* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.MuiGrid .item {
  height: "inherit";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.MuiTableCell-alignRight-314{
  text-align: center !important;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background: #dedede;
}

*::-webkit-scrollbar-thumb {
  background-color: #c4c4c4;
  border-radius: 20px;
}

table thead tr th:not(:first-child) {
  min-width: 125px !important;
}
table thead tr th:first-child{
  width: 45px !important;
}


@media (min-width: 600px) and (max-width: 950px) {
  div[class*="MuiDrawer-paper"] {
    padding-top: 18px;
  }
  .myBox{
    width: 33.3% !important;
  }
}

@media (min-width: 0px) and (max-width: 600px) {
  .MuiDrawer-paper {
    background-color: #424242 !important;
  }
  div.MuiToolbar-regular a.MuiFab-root {
    top: 85vh !important;
  }
  .MuiDrawer-modal,
  .MuiBackdrop-root {
    width: 100vw;
    height: 100vh !important;
    background-color: #eee !important;
  }
  .layout {
    overflow-x: auto !important;
    min-width: auto !important;
  }
  .list-page {
    margin-top: 15px !important;
  }
  .myBox{
    width: 50% !important;
  }
}
